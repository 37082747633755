export const login = {
  email:"",
  password:""
}

export const pregunta = {
    nombre: "",
    temaID:0,
    respuestas: [
      { nombre: "", esCorrecta: false },
      { nombre: "", esCorrecta: false },
      { nombre: "", esCorrecta: false },
      { nombre: "", esCorrecta: false },
    ],
  };
  
  export const tema = {
    nombre: "",
    tiempoMaxMinutos: 0,
    id: 0,
  };
  
  export const alumno = {
    nombre: "",
    email: "",
    licenciaVence: "",
    password: "",
    password2: "",
  };
  
  export const lamina = {
    file: null,
    temaID: 0,
    posicion: 0,
    id: 0,
  };
  
  
  