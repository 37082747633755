import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { clearRespuestasFinal, startPostRespuestas } from "../../context/actions/cotenidoActions";
import CountdownPat from "./CountdownPat";
import Pregunta from "./Pregunta";

export default function Examen2() {
  const [termino, setTermino] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRespuestasFinal())
  }, [])

  const onFinish = async () => {
    //  await dispatch(startPostRespuestas());
    setTermino(true);
  };

  if (termino) return <Redirect to="/confirmacion" />;

  return (
    <div style={{ backgroundColor: "#fff", padding: 10 }}>
      <div style={{ float: "right" }}>
        <CountdownPat minutos={4 * 60} onFinish={onFinish} />
      </div>

      <Pregunta />
    </div>
  );
}
