import { alumnoReducer } from "../reducers/alumnoReducer";
import { contenidoReducer } from "../reducers/contenidoReducer";
import { formasReducer } from "../reducers/formasReducer";
import { loggedReducer } from "../reducers/loggedReducer";
import { preguntaIndexReducer } from "../reducers/preguntaIndex";
import { resultadosReducer } from "../reducers/resultadosReducer";
import { tituloReducer } from "../reducers/tituloReducer";

export const allReducers = {
  loggedReducer: loggedReducer,
  formasReducer: formasReducer,
  contenidoReducer: contenidoReducer,
  alumnoReducer: alumnoReducer,
  resultadosReducer: resultadosReducer,
  preguntaIndexReducer: preguntaIndexReducer,
  tituloReducer: tituloReducer,
};
