import { types } from "../types/types";

const initial = {
  laminas: [],
  laminaIndex: 0,
  preguntaSelected: {},
  laminas: [],
  temas: [],
  tema: {},
  preguntas: [],
  examenes: [],
  examenFinal: [],
  loadingExamenFinal: false,
  historialExamenFinal:[],
  puedeExamenFinal: false,
};

export const contenidoReducer = (state = initial, action) => {
  switch (action.type) {
    /////////////////////////////////

    case types.storeExamenes:
      return {
        ...state,
        examenes: action.payload,
      };
    case types.storeTemas:
      return {
        ...state,
        temas: action.payload,
      };

    case types.setLaminaIndex:
      return {
        ...state,
        laminaIndex: action.payload,
      };

    case types.storeLaminas:
      return {
        ...state,
        laminas: action.payload,
      };

    case types.storePreguntas:
      return {
        ...state,
        preguntas: action.payload,
      };

    case types.temaSelected:
      const temaSelected = state.temas.filter(
        (t) => t.temaID == action.payload
      );
      return {
        ...state,
        tema: temaSelected[0],
      };

    case types.setPreguntaSelected:
      const preguntaSelected = state.preguntas[state.preguntaIndex];
      return {
        ...state,
        preguntaSelected: preguntaSelected,
      };

    ///////////////

    case types.storeExamenFinal:
      return {
        ...state,
        examenFinal: action.payload,
      };

    case types.stateLoadingExamenFinal:
      return {
        ...state,
        loadingExamenFinal: action.payload,
      };


      ///////////

      

      case types.setHistorialExamenFinal:
        return {
          ...state,
          historialExamenFinal: action.payload,
        };



        ////

        case types.puedeExamenFinal:
          return {
            ...state,
            puedeExamenFinal: action.payload,
          };



    default:
      return state;
  }
};
