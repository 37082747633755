import { Col, Layout, Row, Tooltip } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { startLogoff } from "../context/actions/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { colores } from "../constants/colores";

////////////////////////////////////////////////////////////////////////////////
export function LayoutPage({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    user: { Nombre },
  } = useSelector((state) => state.alumnoReducer);
  const { titulo } = useSelector((state) => state.tituloReducer);

  const handleCerrarSesion = () => dispatch(startLogoff());
  const handleMisDatos = () => history.push("/info");
  const handleHome = () => history.push("/");

  ////////////////////////////////////////////////////////////////////////////////
  return (
    <Layout style={styles.contenedor}>
      <Row gutter={20}>
        <Col span={4} style={{ textAlign: "center" }}>
          <Tooltip title="home">
            <img
              onClick={handleHome}
              src="/logo.png"
              style={{ height: 60, cursor: "pointer" }}
            />
          </Tooltip>
        </Col>
        <Col span={13} style={{ paddingTop: 20 }}>
          <div style={styles.cajaTitulo}>
            <span style={styles.titulo}>{titulo}</span>
          </div>
        </Col>
        <Col span={7}>
          <div style={styles.cajaNombre}>
            <Tooltip title="mis datos">
              <span onClick={handleMisDatos} style={styles.nombre}>
                {Nombre}
              </span>
            </Tooltip>
            <span onClick={handleCerrarSesion} style={styles.cerrarSesion}>
              <Tooltip title="cerrar sesión">
                <PoweroffOutlined
                  style={{ color: "orange", fontWeight: "bold" }}
                />
              </Tooltip>
            </span>
          </div>
        </Col>
      </Row>

      <div style={styles.body}>{children}</div>
      <div style={{ width: "100%", textAlign: "right", color: colores.titulo }}>
        v1.15 desarrollado por{" "}
        <a target="_blank" href="https://www.abarden.com">
          Abarden eBuilders
        </a>{" "}
        &copy; {new Date().getFullYear()}
      </div>
    </Layout>
  );
}

const styles = {
  body: {
    width: "100%",
    backgroundColor: colores.backgroundColor,
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
    minHeight: "77vh",
    // height: "77vh",
    // overflowY: "auto",
  },
  nombre: {
    marginLeft: 10,
    fontSize: 20,
    color: "#fff",
    fontWeight: 500,
    cursor: "pointer",
  },
  cerrarSesion: {
    fontSize: 20,
    color: colores.titulo,
    fontWeight: 500,
    marginLeft: 20,
    marginRight: 20,
    cursor: "pointer",
  },
  cajaNombre: {
    width: "100%",
    backgroundColor: colores.backgroundColor,
    borderRadius: 10,
    padding: 10,
    textAlign: "right",
    marginTop: 10,
  },
  titulo: {
    marginLeft: 50,

    fontSize: 20,
    color: "#06124f",
    fontWeight: 500,
  },
  cajaTitulo: {
    width: "100%",
    //  backgroundColor: colores.backgroundColor,
    borderRadius: 10,
    padding: 0,
    marginTop: 10,
  },
  contenedor: {
    minHeight: "100vh",
    padding: 20,
    backgroundColor: "#fff",
    backgroundImage: `url('/FondoAzul16.jpg')`,
    backgroundSize: "100% 100%",
  },
};
