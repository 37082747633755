import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

import { LayoutPage } from "../layout/LayoutPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "../screens/auth/Login";
import Recupera from "../screens/auth/Recupera";
import R from "../screens/auth/R";

import Confirmacion from "../screens/examen/Confirmacion";
import { cargaInicial } from "../context/actions/iniciales";
import { consLogged } from "../constants/consLogged";
import { verificaLogin } from "../context/actions/loginActions";
import ExamenFinal from "../screens/examenFinal/ExamenFinal";
import Examen2 from "../screens/examenFinal/Examen2";
import Confirmacion2 from "../screens/examenFinal/Confirmacion2";

const Info = lazy(() => import("../screens/alumno/Info"));
const Home = lazy(() => import("../screens/home/Home"));
const ShowExamen = lazy(() => import("../screens/examen/ShowExamen"));
const ShowLamina = lazy(() => import("../screens/laminas/ShowLamina"));
const Examen = lazy(() => import("../screens/examen/Examen"));

//////////////////////////////////////////////////////////////////
export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.loggedReducer);
  //const logged = consLogged.LOGGED
  useEffect(() => {
    dispatch(verificaLogin());
    dispatch(cargaInicial());
  }, [dispatch]);

  if (logged === consLogged.INICIANDO) return <div></div>;

  //////////////////////////////////////////////////////////////////
  return (
    <Router>
      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />

        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/recupera"
          component={Recupera}
        />

        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/r/:code"
          component={R}
        />

        <LayoutPage>
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            {/* <PrivateRoute isAuthenticated={logged}  exact path="/" component={Home} /> */}
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/info"
              component={Info}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Home}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/showexamen/:temaID"
              component={ShowExamen}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/showlaminas/:temaID"
              component={ShowLamina}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/examen"
              component={Examen}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/examen2"
              component={Examen2}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/examenfinal"
              component={ExamenFinal}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/confirmacion"
              component={Confirmacion}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/confirmacion2"
              component={Confirmacion2}
            />
          </Suspense>
        </LayoutPage>
      </Switch>
    </Router>
  );
}
