import { Button } from "antd";
import InputPat from "../../components/input/InputPat";
import { colores } from "../../constants/colores";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { get } from "../../utils/fetch";
import { URL_RECUEPRA_PASSWORD } from "../../constants/urls";
import { ArrowLeftOutlined } from "@ant-design/icons";

///
export default function Recupera() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [enviado, setEnviado] = useState(false);

  const history = useHistory();

  const handlePress = async () => {
    setLoading(true);
    if (email !== "") {
      const resul = await get(`${URL_RECUEPRA_PASSWORD}/${email}`);
      if(resul !== -1) {
        setEnviado(true);
      }
    }
    setLoading(false);
 
  };

  return (
    <div style={styles.fondo}>
      <div style={styles.recuadro}>
        <div style={styles.imagenDiv}>
          <img src="/logo.png" style={{ height: 80 }} />
        </div>
        <div style={styles.inputEmailDiv}>
          <InputPat
            label="Email"
            name="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>
        {enviado && (
          <div style={{ paddingBottom: 20 }}>
            Hemos enviado un link a tu correo electrónico, con el cual podrás
            cambiar la contraseña{" "}
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <Button
            disabled={enviado}
            loading={loading}
            onClick={handlePress}
            type="primary"
          >
            Regenerar Contraseña
          </Button>
        </div>
        <div
          onClick={() => history.push("/login")}
          style={{ color: colores.label, cursor: "pointer" }}
        >
          <ArrowLeftOutlined /> regresar
        </div>
      </div>
    </div>
  );
}

const styles = {
  fondo: {
    paddingTop: 100,
    width: "100vw",
    height: "100vh",
    backgroundColor: colores.fondoOscuro,
    textAlign: "center",
  },
  recuadro: {
    backgroundColor: "#fff",
    width: 350,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 20,
    borderRadius: 30,
  },
  imagenDiv: {
    color: colores.label,
    fontSize: 30,
    fontWeight: 500,
    marginBottom: 20,
  },
  inputEmailDiv: {
    marginBottom: 10,
    textAlign: "left",
    padding: 5,
  },
};
