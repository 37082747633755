import React from "react";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import App from "./App";
import "antd/dist/antd.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      swal("Alerta de Aplicación", "Existe una nueva versión", "warning").then(
        (value) => {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
          window.location.reload();
        }
      );
    }
  },
};

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register(configuration);
