import { Tag } from "antd";
import moment from "moment";

export default function useGetColumns() {
  const columns = [
    {
      title: "Fecha",
      dataIndex: "fechaExamen",
      key: "fechaExamen",
      render: (value, row) => moment(value).format("DD-MM-yyyy HH:mm"),
    },
    {
      title: "Respuestas Correctas",
      dataIndex: "preguntasCorrectasFinal",
      key: "preguntasCorrectasFinal",
      align: "center",
    },
    {
      title: "Respuestas Totales",
      dataIndex: "preguntasTotalesFinal",
      key: "preguntasTotalesFinal",
      align: "center",
    },
    {
      title: "Calificación",
      dataIndex: "preguntasTotalesFinal",
      key: "preguntasTotalesFinal",
      align: "center",
      render: (value, { preguntasCorrectasFinal, preguntasTotalesFinal }) => {
    
        const calif =
          preguntasTotalesFinal === 0
            ? "0.0"
            : parseFloat(
                preguntasCorrectasFinal /
                  preguntasTotalesFinal *
                  10
              ).toFixed(1);
        if (calif >= 8) return <Tag color="green">{calif}</Tag>;
        else return <Tag color="magenta">{calif}</Tag>;
      },
    },
  ];

  return columns;
}
