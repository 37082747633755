import React from "react";
import { Route, Redirect } from "react-router-dom";
import { consLogged } from "../constants/consLogged";

////////////////////////////////////////////////////
export default function PrivateRoute({
  isAuthenticated,
  component: Component,
  ...rest
}) {
  ////////////////////////////////////////////////////
  return (
    
      <Route
        {...rest}
        component={(props) =>
          isAuthenticated === consLogged.LOGGED ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
  );
}
