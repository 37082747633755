export const colores = {
  error: "#ff5959",
  label: "#000ba3",
  fondoTituloCard: "#f9f9f9",
  rojo: "#ff051e",
  strokeColorBar: {
    "0%": "#ebf5ff",
    "50%": "#82c3ff",
    "100%": "#1890FF",
  },
  strokeColorCircle: {
    "0%": "#0c962f",
    "25%": "#6edb89",
    "50%": "#0c962f",
    "75%": "#6edb89",
    "100%": "#0c962f",
  },
  fondoOscuro: "#001529",
  titulo: "#2886C4",
  backgroundColor: "rgb(72,84,133,.5)",
};
