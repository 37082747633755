import { LeftCircleOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useHistory } from "react-router-dom"

export default function Regresar({to="/"}) {
    const history = useHistory()
    const handleClick = () => history.push(to)
    return (
        <Button onClick={handleClick} type="link" style={{marginBottom: 10, color: '#fff'}}><LeftCircleOutlined/>Regresar</Button>
    )
}
