import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Regresar from "../../components/regresar/Regresar";
import { colores } from "../../constants/colores";
import {
  setApplicationUserID,
  startLoadExamenFinal,
  startPruedeExamenFinal,
  startSetHistorialExamenFinal,
} from "../../context/actions/cotenidoActions";
import useTitulo from "../../hooks/useTitulo";
import BotonExamen from "./BotonExamen";
import Historial from "./Historial";

/////////////////////////////////////////////////////////////////////////////
export default function ExamenFinal() {
  useTitulo({ titulo: "Examen Final" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setApplicationUserID());
    dispatch(startLoadExamenFinal());
    dispatch(startSetHistorialExamenFinal());
    dispatch(startPruedeExamenFinal());
  }, []);

  /////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Regresar />

      <div
        style={{
          width: "100%",
          color: colores.label,
          fontSize: 18,
        }}
      >
        <Row gutter={20}>
          <Col span={2} style={{ textAlign: "center" }}>
            <InfoCircleOutlined
              style={{ marginTop: 70, fontSize: 80, color: "orange" }}
            />
          </Col>
          <Col span={14}>
            <div>
              <div style={styles.renglon}>
                Para el examen final debes tener acreditados todos los temas, son 220
                preguntas con 4 respuestas de opción múltiple y
                solo una respuesta será la correcta
              </div>
              <div style={styles.renglon}>
                En este examen cuentas con 4 Hrs para su realización, en el
                momento que se termine el tiempo, el examen se cerrará
                automáticamente
              </div>
            </div>
          </Col>
          <Col span={7} style={{ textAlign: "center" }}>
            <BotonExamen />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ paddingTop: 20 }}>
            <Historial />
          </Col>
        </Row>
      </div>
    </>
  );
}

const styles = {
  btnEmpezarExamen: {
    marginTop: 67,
    backgroundColor: "#fff",
    color: "#333",
    borderRadius: 15,
    paddingTop: 20,
    paddingBottom: 20,
    border: ".5px silver solid",
    cursor: "pointer",
    fontSize: 25,
    fontWeight: 500,
  },
  renglon: {
    color: "#333",
    borderRadius: 15,
    padding: 15,
    marginBottom: 10,
    border: ".5px #91C3DC solid",
    backgroundColor: "#fff",
  },
  renglonTitulo: {
    // backgroundColor: "#91C3DC",
    fontWeight: 500,
    fontSize: 23,
    color: "white",
    borderRadius: 15,
    padding: 10,
    marginBottom: 10,
    // border: ".5px #91C3DC solid",
  },
};
