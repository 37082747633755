import { types } from "../types/types";


export const setTema = (data) => ({
    type: types.storeTema,
    payload: data,
  });


  export const setLamina = (data) => ({
    type: types.storeLamina,
    payload: data,
  });

  
  export const setAlumno = (data) => ({
    type: types.storeAlumno,
    payload: data,
  });



  ///////////////////////

  export const setPregunta = (data) => ({
    type: types.storePregunta,
    payload: data,
  });




  export const setRespuesta = (data) => ({
    type: types.storeRespuesta,
    payload: data,
  });

  export const setLogin=(data)=>({
    type: types.storeLogin,
    payload: data,
  })