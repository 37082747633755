import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Confirmacion2() {
  const history = useHistory();
  const { preguntasCorrectasFinal, preguntasTotalesFinal } = useSelector(
    (state) => state.resultadosReducer
  );
  const handleClick = () => history.push("/");

  const calif = parseFloat(
    (preguntasCorrectasFinal / preguntasTotalesFinal) * 10
  ).toFixed(1);

  return (
    <Result
      style={{ backgroundColor: "#fff", paddingBottom: 150 }}
      icon={
        calif >= 8 ? (
          <SmileOutlined />
        ) : (
          <FrownOutlined style={{ color: "orange" }} />
        )
      }
      title={`Examen terminado calificación: ${calif}`}
      subTitle={`Has terminado el examen ${preguntasCorrectasFinal} respuestas fueron correctas de un total de ${preguntasTotalesFinal} preguntas`}
      extra={[
        <Button onClick={handleClick} type="primary" key="console">
          Regresar al inicio
        </Button>,
      ]}
    />
  );
}
