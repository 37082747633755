import { Card, Table } from "antd"
import { useSelector } from "react-redux"
import useGetColumns from "./useGetColumns"

export default function Historial() {

    const {historialExamenFinal} = useSelector(state => state.contenidoReducer)
const colums = useGetColumns()
    return (
        <Card >
            <Table dataSource={historialExamenFinal} columns={colums} rowKey="id" />

        </Card>
    )
}
