import { types } from "../types/types";

const initial = {
  temaID: 0,
  ApplicationUserID: 0,
  preguntasCorrectas: 0,
  preguntasTotales: 0,
  preguntasCorrectasFinal: 0,
  preguntasTotalesFinal: 0,
};

export const resultadosReducer = (state = initial, action) => {
  switch (action.type) {
    /////////////////////////////////

    case types.storeResultadosItem:
      return {
        ...state,
        ...action.payload,
      };

    case types.addRespuesta:
      const newCorrectas = state.preguntasCorrectas + action.payload;

      return {
        ...state,
        preguntasCorrectas: newCorrectas,
      };

    case types.addRespuesta2:
      const newCorrectas2 = state.preguntasCorrectasFinal + action.payload;
      console.log(newCorrectas2);
      return {
        ...state,
        preguntasCorrectasFinal: newCorrectas2,
      };

    case types.storeRespuestasInicial:
      return {
        ...state,
        ...action.payload,
      };

    case types.clearRespuestasFinal:
      return {
        ...state,
        preguntasCorrectasFinal: 0,
      };

    default:
      return state;
  }
};
