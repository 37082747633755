import { types } from "../types/types";
import { get, post } from "../../utils/fetch";
import {
  URL_GET_LAMINAS_ALUMNO,
  URL_GET_TEMAS_ALUMNO,
  URL_GET_PREGUNTAS_ALUMNO,
  URL_ADD_RESULTADO,
  URL_UPD_LAMINA,
  URL_EXAMENES,
  URL_EXAMEN_FINAL,
  URL_ADD_RESULTADO_FINAL,
  URL_GET_HISTORIAL_FINAL,
  URL_PUEDE_EXAMEN_FINAL,
} from "../../constants/urls";

//////////////////////////////////////////////////////////

export const startFetchTemas = () => {
  return async (dispatch, getState) => {
    const {
      user: { Id },
    } = getState().alumnoReducer;
    const url = `${URL_GET_TEMAS_ALUMNO}/${Id}`;

    const data = await get(url);
    dispatch(storeTemas(data));
  };
};

const storeTemas = (data) => ({
  type: types.storeTemas,
  payload: data,
});

////////////////////////////////////////////////////////////////////////////////////////////////////
//// set lamina index

export const setLaminaIndex = (index) => ({
  type: types.setLaminaIndex,
  payload: index,
});

////////////////////////////////////////////////////////////////////////////////////////////////////
//// start download laminas

export const startDownloadLaminas = (id) => {
  return async (dispatch) => {
    const url = `${URL_GET_LAMINAS_ALUMNO}/${id}`;
    const data = await get(url);
    dispatch(storeLaminas(data));
  };
};

const storeLaminas = (data) => ({
  type: types.storeLaminas,
  payload: data,
});

////////////////////////////////////////////////////////////////////////////////////////////////////
//// start download laminas

export const startDownloadPreguntas = (id) => {
  return async (dispatch) => {
    const url = `${URL_GET_PREGUNTAS_ALUMNO}/${id}`;
    const data = await get(url);
    dispatch(storePreguntas(data));
  };
};

const storePreguntas = (data) => ({
  type: types.storePreguntas,
  payload: data,
});

////////////////////

export const storeTemaSelected = (data) => ({
  type: types.temaSelected,
  payload: data,
});

///////////
export const setPreguntaSelected = () => ({
  type: types.setPreguntaSelected,
  payload: null,
});

///////////
export const setPreguntaIndex = (index) => ({
  type: types.setPreguntaIndex,
  payload: index,
});

////////
export const agregaRespuestaCorrecta = () => ({
  type: types.addRespuestaCorrecta,
  payload: null,
});

export const startStoreRespuestasInicial = (temaID) => {
  return (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
    const { preguntas } = getState().contenidoReducer;

    const salida = {
      ApplicationUserID: user.Id,
      preguntasTotales: preguntas.length,
      preguntasCorrectas: 0,
      temaID,
    };
    dispatch(storeRespuestasInicial(salida));
  };
};

const storeRespuestasInicial = (data) => ({
  type: types.storeRespuestasInicial,
  payload: data,
});

/////
export const addRespuesta = (correcta) => {
  return {
    type: types.addRespuesta,
    payload: correcta,
  };
};

/////
export const addRespuesta2 = (correcta) => {
  return {
    type: types.addRespuesta2,
    payload: correcta,
  };
};

////

export const startPostRespuestas = () => {
  return async (dispatch, getState) => {
    const resultados = getState().resultadosReducer;
    console.log({ resultados });
    await post(URL_ADD_RESULTADO, resultados, false);
    await dispatch(setPreguntaIndex(0));
    await dispatch(startFetchTemas());
    dispatch(startLoadExamenes());
  };
};

export const startPostRespuestasFinal = () => {
  return async (dispatch, getState) => {
    const resultados = getState().resultadosReducer;
    console.log({ resultados });
    const { examenFinal } = getState().contenidoReducer;
    console.log({ examenFinal });

    const total = { preguntasTotalesFinal: examenFinal.length };
    const body = { ...resultados, ...total };
    console.log({ body });

    await post(URL_ADD_RESULTADO_FINAL, body, false);
    await dispatch(setPreguntaIndex(0));
    await dispatch(storeResultadosItem(total));
  };
};

////////

export const startUpdLaminaVista = (data) => {
  return (dispatch) => {
    post(URL_UPD_LAMINA, data, false);
  };
};

export const setTitulo = (titulo) => ({
  type: types.setTitulo,
  payload: titulo,
});

///// EXAMENES DE ALUMNO
export const startLoadExamenes = () => {
  return async (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
    const url = `${URL_EXAMENES}/${user.Id}`;
    const data = await get(url);

    dispatch(storeExamenes(data));
  };
};

const storeExamenes = (data) => ({
  type: types.storeExamenes,
  payload: data,
});

//////////////////////////////////////////////////////////////////////

export const startClearPreguntas = () => {
  return (dispatch) => {
    dispatch(clearPreguntaIndex());
  };
};

const clearPreguntaIndex = () => ({
  type: types.clearPreguntaIndex,
  payload: null,
});

//////////////////////////////////////////////////////////////////////
export const startLoadExamenFinal = () => {
  return async (dispatch, getState) => {
    dispatch(stateLoadingExamenFinal(true));
    const data = await get(URL_EXAMEN_FINAL);

    dispatch(storeExamenFinal(data));
    dispatch(stateLoadingExamenFinal(false));
  };
};

const stateLoadingExamenFinal = (state) => ({
  type: types.stateLoadingExamenFinal,
  payload: state,
});

const storeExamenFinal = (examenFinal) => ({
  type: types.storeExamenFinal,
  payload: examenFinal,
});

export const setApplicationUserID = () => {
  return (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
    dispatch(storeResultadosItem({ ApplicationUserID: user.Id }));
  };
};

const storeResultadosItem = (item) => ({
  type: types.storeResultadosItem,
  payload: item,
});

//////
export const startSetHistorialExamenFinal = () => {
  return async (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
    const data = await get(`${URL_GET_HISTORIAL_FINAL}/${user.Id}`);
    dispatch(setHistorialExamenFinal(data));
  };
};

const setHistorialExamenFinal = (data) => ({
  type: types.setHistorialExamenFinal,
  payload: data,
});

////////////

export const startPruedeExamenFinal = () => {
  return async (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
   
    const state = await get(`${URL_PUEDE_EXAMEN_FINAL}/${user.Id}`);
    console.log(state)
    dispatch(puedeExamenFinal(state))

  }
}


const puedeExamenFinal = (state) => ({
  type: types.puedeExamenFinal,
  payload: state
})


////////////
export const clearRespuestasFinal = () => ({
  type: types.clearRespuestasFinal,
  payload: null
})