import { types } from "../types/types";

const initial = {
 user:{}
};

export const alumnoReducer = (state = initial, action) => {
  switch (action.type) {
    /////////////////////////////////
    case types.storeAlumno:
      return {
        ...state,
        user: action.payload,
      };

      case types.setChangeUser:
      return {
        ...state,
        user: {...state.user, ...action.payload},
      };

    default:
      return state;
  }
};
