const isProd = true;
export const svr = isProd
  ? "https://back.impulsafinanzas.com"
  : "https://localhost:44359";

////////
export const URL_LOGIN_ALUMNO = `${svr}/api/AuthAlumno/login`;
export const URL_UPD_USER = `${svr}/api/AuthAlumno/updUser`;

export const URL_GET_TEMAS_ALUMNO = `${svr}/api/ContenidosAlumno/temas`;
export const URL_GET_PREGUNTAS_ALUMNO = `${svr}/api/ContenidosAlumno/preguntas`;
export const URL_GET_LAMINAS_ALUMNO = `${svr}/api/ContenidosAlumno/laminas`;
export const URL_ADD_RESULTADO = `${svr}/api/ContenidosAlumno/AddResultado`;
export const URL_ADD_RESULTADO_FINAL = `${svr}/api/ContenidosAlumno/AddResultadoFinal`;

export const URL_UPD_LAMINA = `${svr}/api/ContenidosAlumno/updLaminaVista`;
export const URL_EXAMENES = `${svr}/api/ContenidosAlumno/Examenes`;
export const URL_EXAMEN_FINAL = `${svr}/api/ContenidosAlumno/ExamenFinal`;
export const URL_GET_HISTORIAL_FINAL = `${svr}/api/ContenidosAlumno/GetHistoricoExamenFinal`;

export const URL_PUEDE_EXAMEN_FINAL = `${svr}/api/ContenidosAlumno/PuedeExamenFinal`;

export const URL_RECUEPRA_PASSWORD = `${svr}/api/auth/RecoverPassword`;
export const URL_RESET_PASSWORD = `${svr}/api/auth/ResetPasswordAlumno`;
