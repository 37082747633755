import { Button, Radio, Space, Progress } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
//
import { colores } from "../../constants/colores";
import {
  addRespuesta,
  addRespuesta2,
  setPreguntaIndex,
  startPostRespuestas,
  startPostRespuestasFinal,
} from "../../context/actions/cotenidoActions";

////////////////////////////////////////////////////////////////////////////////////////////////
export default function Pregunta() {
  const [showCorrecta, setShowCorercta] = useState(false);
  const [estaValidado, setEstaValidado] = useState(false);
  const [terminado, setTerminado] = useState(false);
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const { examenFinal: preguntas  } = useSelector((state) => state.contenidoReducer);

  const { preguntaIndex } = useSelector((state) => state.preguntaIndexReducer);

  const pregunta = preguntas[preguntaIndex];



  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleContinuar = () => {
    if (!estaValidado) {
      //
      let correcta = 0;
      pregunta.respuestas.map((r) => {
        if (r.esCorrecta && r.id === value) correcta++;
      });

      dispatch(addRespuesta2(correcta));
      setEstaValidado(true);
      setShowCorercta(true);
    } else {
      setShowCorercta(false);
      setEstaValidado(false);
      setValue(null);
      //
      const newIndex = preguntaIndex + 1;
      if (newIndex >= preguntas.length) {
        dispatch(startPostRespuestasFinal());
        setTerminado(true);
      } else {
        dispatch(setPreguntaIndex(preguntaIndex + 1));
      }
    }
  };

  if (terminado) return <Redirect to="/confirmacion2" />;
  if(!pregunta) return <Redirect to="/examenfinal" />;
  ////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          marginLeft: 30,
          color: colores.label,
          fontWeight: 500,
          fontSize: 20,
          width: "80%",
          backgroundColor: '#fff'
        }}
      >
        {pregunta.pregunta}
      </div>

      <div style={{ marginLeft: 100, marginTop: 50 }}>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            {pregunta.respuestas.map((r, i) => {
              const color = showCorrecta && r.esCorrecta ? "#3fe639" : "";
              return (
                <div style={{ marginBottom: 5 }} key={i}>
                  <CheckCircleOutlined />

                  <Radio value={r.id}>
                    <span style={{ color, fontWeight: 500 }}>{r.nombre}</span>
                  </Radio>
                </div>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
      <div style={{ marginTop: 40, marginLeft: 100 }}>
        <Button
          style={{ marginTop: 10, width: 230 }}
          onClick={handleContinuar}
          type="primary"
          disabled={!value ? true : false}
        >
          {estaValidado ? "Continuar" : "Validar Respuesta"}
        </Button>
      </div>

      <div style={{  width: "50%", marginTop: 20 }}>
        <Progress
          percent={(preguntaIndex / preguntas.length) * 100}
          showInfo={false}
        />
        {preguntaIndex + 1} de {preguntas.length}
      </div>
    </div>
  );
}
