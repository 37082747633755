export const types = {
  storeLogin: "[login] store login",
  setLogged: "[login] set logged",

  storeAlumno: "[user] stote alumno info",
  storeTemas: "[contenidos] stote temas temas",
  storeLaminas: "[contenidos] stote temas laminas",
  storePreguntas: "[contenidos] stote temas preguntas",

  setLaminaIndex: "[contenidos] set lamina index",
  storeLaminas: "[contenidos] store laminas",
  storePreguntas: "[contenidos] store preguntas",
  temaSelected: "[contenidos] set tema selected",
  setPreguntaSelected: "[contenidos] set pregunta selected",
  setPreguntaIndex: "[contenidos] set pregunta index",
  addRespuestaCorrecta: "[contenidos] add respuesta correcta",
  storeRespuestasInicial: "[contenidos] respuesta inicial",
  addRespuesta: "[contenidos] resultado respuesta",
  addRespuesta2: "[contenidos] resultado respuesta1",
  storeResultadosItem: "[storeResultadosItem] storeResultadosItem",

  setTitulo: "[contenidos] set titulo",
  setChangeUser: "[user] cambia datos",
  storeExamenes: "[contenidos] store examenes",
  stateLoadingExamenFinal: "[contenidos] loading examen final",
  storeExamenFinal: "[contenidos] examen final",

  clearPreguntaIndex: "[contenidos] clear pregunta index",
  setHistorialExamenFinal: "[setHistorialExamenFinal] setHistorialExamenFinal",
  puedeExamenFinal: "[puedeExamenFinal] puedeExamenFinal",

  clearRespuestasFinal: "clearRespuestasFinal",
};
