import { types } from "../types/types";

const initial = {
  logged: "iniciando"
};

export const loggedReducer = (state = initial, action) => {
  switch (action.type) {

        case types.setLogged:
          return { ...state, logged: action.payload };

    default:
      return state;
  }
};
