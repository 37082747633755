import { Spin } from "antd";
import { useSelector } from "react-redux";
import {ArrowRightOutlined, CheckCircleOutlined} from '@ant-design/icons'
import { useHistory } from "react-router-dom";

/////////////////////////////////
export default function BotonExamen() {
 const history =  useHistory()
  const { examenFinal, loadingExamenFinal, puedeExamenFinal } = useSelector(
    (state) => state.contenidoReducer
  );

  const comienzaExamen = () => history.push("/examen2")

  ////////////////////////////////////////////
  if (loadingExamenFinal)
    return (
      <div style={styles.botonExamen}>
        <div>Cargando Preguntas</div>
        <Spin />
      </div>
    );

  if (examenFinal.length === 0)
    return (
      <div style={styles.botonExamen}>
        no hay preguntas aún
      </div>
    );

    if (!puedeExamenFinal)
    return (
      <div style={styles.botonExamen}>
        Debe acreditar todos los temas para efectuar examen final
      </div>
    );


    

  return (
    <>
      <div style={styles.botonExamen}>
        <div style={{ marginBottom: 15 }}><CheckCircleOutlined /> Examen Cargado</div>
        <div onClick={comienzaExamen} style={{cursor: 'pointer', color: 'orange'}}><ArrowRightOutlined /> Comenzar Examen</div>
      </div>
    </>
  );
}

const styles = {
  botonExamen: {
    padding: 50,
    backgroundColor: "#fff",
    fontWeight: 500,
    fontSize: 20,
    borderRadius: 15,
  },
};
