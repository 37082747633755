import { types } from "../types/types";

const initial = {
 titulo:""
};

export const tituloReducer = (state = initial, action) => {
  switch (action.type) {
    /////////////////////////////////
    case types.setTitulo:
      return {
        ...state,
        titulo: action.payload,
      };

    default:
      return state;
  }
};
