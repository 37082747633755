import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { consLogged } from '../constants/consLogged';


export default function PublicRoute({
isAuthenticated,
component: Component,
...rest
}) {
    return (
       <Route {...rest} 
       component = {(props) => (
           (isAuthenticated !== consLogged.LOGGED ) ? (<Component {...props} />) : (<Redirect to="/" />)
       )}
       />
    )
}
