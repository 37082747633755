import { consLogged } from "../../constants/consLogged";
import { URL_LOGIN_ALUMNO, URL_UPD_USER } from "../../constants/urls";
import { post } from "../../utils/fetch";
import { types } from "../types/types";
import parseJwt from "../../utils/parseJwt";
////
export const startLogoff = () => {
  localStorage.removeItem("token");
  return (dispatch) => dispatch(setLogged(consLogged.NOTLOGGED));
};

//////////////////////////////////////////////////////////

export const verificaLogin = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(storeAlumno(parseJwt(token)));
      dispatch(setLogged(consLogged.LOGGED));
    } else {
      dispatch(setLogged(consLogged.NOTLOGGED));
    }
  };
};

const storeAlumno = (alumno) => ({
  type: types.storeAlumno,
  payload: alumno,
});
////////////////////////////////////////////////////////
export const startLogin = () => {
  return async (dispatch, getState) => {
    const { login } = getState().formasReducer;
    const data = await post(URL_LOGIN_ALUMNO, login, false);
    if (data?.token) {
      localStorage.setItem("token", data.token);
      dispatch(storeAlumno(parseJwt(data.token)));
      dispatch(setLogged(consLogged.LOGGED));
    }
  };
};

export const setLogged = (val) => ({
  type: types.setLogged,
  payload: val,
});

////////////////////////////////////
/// Para actualzair datos de usario

export const setChangeUser = (target) => {
  console.log(target);
  return {
    type: types.setChangeUser,
    payload: { [target.name]: target.value },
  };
};

export const startUpdUser = () => {
  return (dispatch, getState) => {
    const { user } = getState().alumnoReducer;
    let body = {
      Nombre: user.Nombre,
      Email: user.Email,
    };
    if (user.Password) body = { ...body, Password: user.Password };

    post(`${URL_UPD_USER}/${user.Id}`, body);
  };
};
