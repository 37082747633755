import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputPassPat from "../../components/input/InputPassPat";
import InputPat from "../../components/input/InputPat";
import { colores } from "../../constants/colores";
import { setLogin } from "../../context/actions/formasActions";
import { startLogin } from "../../context/actions/loginActions";
import { useHistory } from "react-router-dom";

export default function Login() {
  const history = useHistory();

  const {
    login: { email, password },
  } = useSelector((state) => state.formasReducer);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const dato = {
      [e.target.name]: e.target.value,
    };
    dispatch(setLogin(dato));
  };

  const handlePress = () => dispatch(startLogin());

  return (
    <div
      style={{
        paddingTop: 100,
        width: "100vw",
        height: "100vh",
        backgroundColor: colores.fondoOscuro,
        textAlign: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: 350,
          marginLeft: "auto",
          marginRight: "auto",
          padding: 20,
          borderRadius: 30,
        }}
      >
        <div
          style={{
            color: colores.label,
            fontSize: 30,
            fontWeight: 500,
            marginBottom: 20,
          }}
        >
          <img src="/logo.png" style={{ height: 80 }} />
        </div>
        <div
          style={{
            marginBottom: 10,
            textAlign: "left",
            padding: 5,
          }}
        >
          <InputPat
            label="Email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            marginBottom: 10,
            textAlign: "left",
            padding: 5,
          }}
        >
          <InputPassPat
            label="Password"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <Button onClick={handlePress} type="primary">
            Entrar
          </Button>
        </div>
        <div
          onClick={() => history.push("/recupera")}
          style={{ color: colores.label, cursor: "pointer" }}
        >
          recuperar contarseña
        </div>
      </div>
    </div>
  );
}
