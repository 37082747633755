import { types } from "../types/types";

const initial = {
    preguntaIndex: 0,
};

export const preguntaIndexReducer = (state = initial, action) => {
  switch (action.type) {
    /////////////////////////////////

    case types.setPreguntaIndex:
      return {
        ...state,
        preguntaIndex: action.payload,
      };

      
    case types.clearPreguntaIndex:
      return {
        ...state,
        preguntaIndex: 0,
      };

    default:
      return state;
  }
};
