import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTitulo } from "../context/actions/cotenidoActions";

export default function useTitulo({ titulo }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitulo(titulo));
  }, [dispatch]);

}
