import { Button } from "antd";
import InputPat from "../../components/input/InputPat";
import { colores } from "../../constants/colores";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { post } from "../../utils/fetch";
import { URL_RESET_PASSWORD } from "../../constants/urls";
import { ArrowLeftOutlined } from "@ant-design/icons";
import InputPassPat from "../../components/input/InputPassPat";
import { TreeLayout } from "@antv/g6-pc";

///
export default function R() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [ok, setOk] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errText, setErrText] = useState("");

  const { code } = useParams();
  const history = useHistory();

  //
  const handlePress = async () => {
    if (password.length < 6) {
      setErrText(
        "La contraseña debe ser de 6 caracteres, incluir mayúscula, minúscula, número y caracter especial como * , !"
      );
      return;
    }

    if (password !== password2) {
      setErrText("Las contraseñas no coinciden");
      return;
    }
    setLoading(true);
    const body = {
      code,
      email,
      password,
    };

    const resul = await post(URL_RESET_PASSWORD, body, null);
    if (resul !== -1) {
      setOk(true);
    }

    setLoading(false);
  };

  return (
    <div style={styles.fondo}>
      <div style={styles.recuadro}>
        <div style={styles.imagenDiv}>
          <img src="/logo.png" style={{ height: 80 }} />
        </div>
        <div style={styles.inputEmailDiv}>
          <InputPat
            label="Email"
            name="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>

        <div style={styles.inputEmailDiv}>
          <InputPassPat
            label="Contraseña"
            name="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </div>

        <div style={styles.inputEmailDiv}>
          <InputPassPat
            label="Confirmar contraseña"
            name="password2"
            value={password2}
            onChange={({ target }) => setPassword2(target.value)}
          />
        </div>

        {ok && (
          <div style={{ paddingBottom: 20, textAlign: "center" }}>
            Contraseña cambiada satisfactoriamente
          </div>
        )}
        {errText !== "" && (
          <div style={{ paddingBottom: 20, textAlign: "center", color: "red" }}>
            {errText}
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <Button
            disabled={ok}
            loading={loading}
            onClick={handlePress}
            type="primary"
          >
            Establecer Contraseña
          </Button>
        </div>
        <div
          onClick={() => history.push("/login")}
          style={{ color: colores.label, cursor: "pointer" }}
        >
          <ArrowLeftOutlined /> ir a login
        </div>
      </div>
    </div>
  );
}

const styles = {
  fondo: {
    paddingTop: 100,
    width: "100vw",
    height: "100vh",
    backgroundColor: colores.fondoOscuro,
    textAlign: "center",
  },
  recuadro: {
    backgroundColor: "#fff",
    width: 350,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 20,
    borderRadius: 30,
  },
  imagenDiv: {
    color: colores.label,
    fontSize: 30,
    fontWeight: 500,
    marginBottom: 20,
  },
  inputEmailDiv: {
    marginBottom: 10,
    textAlign: "left",
    padding: 5,
  },
};
