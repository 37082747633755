import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Confirmacion() {
  const history = useHistory();
  const { preguntasCorrectas, preguntasTotales } = useSelector(
    (state) => state.resultadosReducer
  );
  const handleClick = () => history.push("/");

  const calif = parseFloat(preguntasCorrectas/preguntasTotales*10).toFixed(1)

  return (
    <Result
    style={{backgroundColor: '#fff', paddingBottom: 150}}
      icon={calif >= 8 ? (<SmileOutlined  />) : (<FrownOutlined style={{color: 'orange'}}/>)}
      title={`Examen terminado calificación: ${calif}`}
      subTitle={`Has terminado el examen ${preguntasCorrectas} respuestas fueron correctas de un total de ${preguntasTotales} preguntas`}
      extra={[
        <Button onClick={handleClick} type="primary" key="console">
          Regresar al inicio
        </Button>,
      ]}
    />
  );
}
